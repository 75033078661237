<template>
  <div>
    <b-card class="mt-2">
      <b-card-title>
        <div class="d-flex justify-content-between">
          <div>Baixar XML em Lote</div>
          <div>
            <b-button
              variant="success"
              @click="openSolicitaXML"
              ><b-icon-plus />Nova Solicitação</b-button
            >
          </div>
        </div>
      </b-card-title>
      <div
        class="text-center my-4"
        v-if="loading"
      >
        <b-spinner /> carregando

        <b-skeleton-table
          type="table"
          v-if="loading"
        />
      </div>

      <b-table
        v-if="!loading"
        :fields="[
          { key: 'created_at', label: 'Solicitaçao' },
          { key: 'usuario.nome', label: 'Usuário' },
          { key: 'periodo', label: 'Periodo' },
          { key: 'empresas', label: 'Empresa(s)' },
          { key: 'opc', label: '' },
        ]"
        :items="lista"
        :busy="loading"
        :loading="loading"
        hover
      >
        <template #cell(created_at)="row">
          {{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}
        </template>
        <template #cell(periodo)="row">
          {{ row.item.data_inicial | moment("DD/MM/YYYY") }}
          á
          {{ row.item.data_final | moment("DD/MM/YYYY") }}
        </template>
        <template #cell(empresas)="row">
          <span
            size="sm"
            class="badge badge-primary"
            small
            v-for="emp in row.item.empresas"
            :key="emp.cod_empresa"
          >
            {{ emp.nome }}
          </span>
        </template>
        <template #cell(opc)="row">
          <b-button
            variant="warning"
            size="sm"
            @click="openDetail(row.item)"
          >
            <b-icon-eye />
          </b-button>
          <b-button
            class="ml-1"
            variant="info"
            size="sm"
            @click="baixarXML(row.item.id)"
          >
            <b-icon-download />
          </b-button>
          <b-button
            class="ml-1"
            variant="danger"
            size="sm"
            @click="baixarXML(row.item.id)"
          >
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="modal-solicita-xml"
      hide-footer
    >
      <template #modal-title> Solicitar XML </template>

      <div>
        <b-form-group label="Empresa">
          <v-select
            style="max-width: 500px; display: block"
            multiple
            v-model="obj.empresas"
            :options="empresas"
            :reduce="(emp) => emp.cod_empresa"
            label="nome"
          />
        </b-form-group>
        <b-form-group label="Período">
          <v-date-picker
            v-model="range"
            is-range
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input
                  :value="`${inputValue.start} - ${inputValue.end}`"
                  v-on="inputEvents.start"
                  class="form-control"
                />
              </div>
            </template>
          </v-date-picker>
        </b-form-group>
        <b-form-group label="Situação">
          <v-select
            v-model="obj.status"
            :options="[
              { label: 'Erro', value: 'Erro' },
              { label: 'Concluído', value: 'Concluido' },
            ]"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group label="Ambiente">
          <b-radio-group
            v-model="obj.ambiente"
            :options="[
              { text: 'Produção', value: '1' },
              { text: 'Homologação', value: '2' },
            ]"
          />
        </b-form-group>
      </div>

      <div class="text-right">
        <b-button
          variant="success"
          @click="requestXML"
          :disabled="solicitando"
        >
          <b-spinner
            small
            v-if="solicitando"
          />
          Solicitar Lote XML</b-button
        >
        <b-button
          variant="secondary"
          class="ml-2"
          @click="closeRequestXML"
          >Cancelar</b-button
        >
      </div>
    </b-modal>
    <b-modal
      size="lg"
      id="modal-detail-xml"
      hide-footer
    >
      <template #modal-title> Detalhes do XML </template>
      <div
        v-if="objEdit && objEdit.id"
        class="row"
      >
        <div
          class="col-12 col-md-3"
          style="border-right: 1px solid #dedede"
        >
          <div>
            <b>Data Solicitação</b>
            <p>{{ objEdit.created_at | moment("DD/MM/YYYY HH:mm") }}</p>
          </div>
          <div>
            <b>Usuário</b>
            <p>{{ objEdit.usuario.nome }}</p>
          </div>
          <div>
            <b>Período</b>
            <p>
              {{ objEdit.data_inicial | moment("DD/MM/YYYY") }}
              á
              {{ objEdit.data_final | moment("DD/MM/YYYY") }}
            </p>
          </div>
          <div>
            <b>Empresas</b>
            <p>
              <span
                size="sm"
                class="badge badge-primary"
                small
                v-for="emp in objEdit.empresas"
                :key="emp.cod_empresa"
              >
                {{ emp.nome }}
              </span>
            </p>
          </div>
          <div>
            <b>Ambiente</b>
            <p>{{ objEdit.ambiente == 1 ? "Produção" : "Homologação" }}</p>
          </div>
          <div>
            <b>Situação</b>
            <p>{{ objEdit.status }}</p>
          </div>
          <b-button
            variant="info"
            size=""
            block
            @click="baixarXML(objEdit.id)"
            class="mt-2"
          >
            <b-icon-download />
            Baixar Lote XML
          </b-button>
        </div>
        <div class="col-12 col-md-8">
          <b-table
            small
            style="font-size: 10px"
            :fields="[
              { key: 'numero', label: 'Número' },
              { key: 'serie', label: 'Série' },
              { key: 'arquivo', label: 'Arquivo' },
            ]"
            :items="objEdit.xmls"
            :busy="loading"
            :loading="loading"
            hover
          >
            <template #cell(opc)="row">
              <b-button
                variant="info"
                size="sm"
                @click="baixarXML(row.item.chave)"
              >
                <b-icon-download />
              </b-button>
            </template>
          </b-table>
        </div>
      </div>
      <div class="text-right">
        <b-button
          variant="secondary"
          @click="closeRequestXML"
          >Fechar</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
// import SelectEmpresaCaixa from "../../components/common/SelectEmpresaCaixa.vue";
import moment from "moment";
import FiscalLib from "../../libs/FiscalLib";
export default {
  components: {
    // SelectEmpresaCaixa,
  },
  props: {},
  mounted() {
    this.empresas = [
      this.$store.state.empresa_ativa,
      ...this.$store.state.empresa_ativa.empresas,
    ];
    this.loadItems();
  },
  data() {
    return {
      range: {
        start: moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
        end: moment().endOf("month").format("YYYY-MM-DD 23:59:59"),
      },
      empresas: [],
      obj: {
        data_inicial: moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
        data_final: moment().endOf("month").format("YYYY-MM-DD 23:59:59"),
        empresas: [this.$store.state.empresa_ativa.cod_empresa],
        status: "Concluido",
        ambiente: "1",
      },
      solicitando: false,
      lista: [],
      loading: true,
      objEdit: false,
    };
  },
  watch: {
    range(val) {
      this.obj.data_inicial = moment(val.start).format("YYYY-MM-DD");
      this.obj.data_final = moment(val.end).format("YYYY-MM-DD");
    },
  },
  computed: {},
  methods: {
    async loadItems() {
      this.loading = true;
      await this.$nextTick();
      this.lista = [];

      try {
        let res = await FiscalLib.getDownloadXML({});
        console.log("res", res);
        if (res && res.success) {
          this.lista = res.data;
        }
      } catch (error) {
        console.error("error", error);
      }

      this.loading = false;
    },
    openSolicitaXML() {
      this.$bvModal.show("modal-solicita-xml");
    },
    closeRequestXML() {
      this.$bvModal.hide("modal-solicita-xml");
      this.$bvModal.hide("modal-detail-xml");
      this.loadItems();
    },
    async requestXML() {
      console.log("request", this.obj);
      if (!this.obj.empresas.length) {
        this.$swal({
          title: "Atenção",
          text: "Selecione ao menos uma empresa",
          icon: "warning",
        });
        return;
      }
      if (!this.obj.data_inicial || !this.obj.data_final) {
        this.$swal({
          title: "Atenção",
          text: "Selecione um período",
          icon: "warning",
        });
        return;
      }

      this.solicitando = true;
      try {
        let result = await FiscalLib.solicitarXML(this.obj);
        console.log("result", result);
        if (result && result.success && result.data.id) {
          this.$swal({
            title: "Solicitação realizada com sucesso",
            icon: "success",
          });
          this.closeRequestXML();
          this.$swal({
            title: "Deseja baixar o arquivo de lote de xml?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          }).then(async (x) => {
            if (x.isConfirmed) {
              await FiscalLib.baixarXML(result.data.id);
              // console.log("result", result);
              // if(result && result.success){
              //   this.$swal({
              //     title: "Arquivo de lote de xml baixado com sucesso",
              //     icon: "success",
              //   });
              // } else {
              //   this.$swal({
              //     title: "Erro",
              //     text: "Erro ao baixar XML",
              //     icon: "error",
              //   });
              // }
            }
          });
        } else {
          this.$swal({
            title: "Erro",
            text: "Erro ao solicitar XML",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("error", error);
        this.$swal({
          title: "Erro",
          text: "Erro ao solicitar XML",
          icon: "error",
        });
      }
      this.loadItems();

      this.solicitando = false;
    },
    baixarXML(item) {
      console.log("baixar", item);
      this.$swal({
        title: "Deseja baixar o arquivo de lote de xml?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (x) => {
        if (x.isConfirmed) {
          await FiscalLib.baixarXML(item);
        }
      });
    },
    openDetail(item) {
      this.objEdit = item;
      this.$bvModal.show("modal-detail-xml");
    },
  },
};
</script>

<style lang="scss" scoped></style>
